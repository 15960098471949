
import {
  computed,
  reactive,
  defineComponent,
  onMounted,
  ref,
  ComponentPublicInstance,
  onUnmounted
} from "vue";
import Step1 from "@/components/users/steps/Step1.vue";
import UserCreateFields from "@/components/users/steps/UserCreateFields.vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import { useStore } from "vuex";
import { useAbility } from "@casl/vue";
import router from "@/router/index";

export default defineComponent({
  name: "create-user-wizard",
  components: {
    Step1,
    //LoadingSpinner,
    UserCreateFields
  },
  setup() {
    const store = useStore();
    const step = ref<number>(1);
    const user = computed(() => store.getters["CreateUserModule/user"]);
    const role = computed(() => store.getters["CreateUserModule/role"]);
    const fields = computed(() => store.getters["CreateUserModule/fields"]);
    const loading = computed(() => store.getters["CreateUserModule/loading"]);
    const key = ref<number>(0);
    const inputs = ref<ComponentPublicInstance<typeof UserCreateFields>>();
    const isAtLastStep = computed(() => {
      return step.value !== 1 && step.value == fields.value.steps.length + 1;
    });
    onMounted(() => {
      if (!useAbility().can("user_create", "all")) {
        router.push("/");
      }
    });
    function handleStepIncrement() {
      if (role.value.length !== 0 && step.value == 1) {
        store
          .dispatch("CreateUserModule/getFields", {
            roleID: role.value,
            step: step.value
          })
          .then(() => {
            step.value++;
          });
      } else {
        inputs.value?.validateInputs();
      }
    }
    function handleBack() {
      step.value--;
      if (step.value == 1) {
        store.dispatch("CreateUserModule/clearFieldsAndRole");
      } else {
        store
          .dispatch("CreateUserModule/getFields", {
            roleID: role.value,
            step: step.value - 1
          })
          .then(() => {
            inputs.value?.handleBackButton();
            key.value++;
          });
      }
    }
    function handleChangePaymentAccount(data) {
      store
        .dispatch("CreateUserModule/getFields", {
          roleID: role.value,
          step: step.value - 1,
          fields: `${data == "" ? "default" : data}`
        })
        .then(() => {
          key.value++;
        })
        .finally(() => {
          inputs.value?.populatePaymentField(data);
        });
    }
    function handleNextStep() {
      step.value++;
      inputs.value?.saveDataToState();
      if (isAtLastStep.value && fields.value.steps.length > 1) {
        store
          .dispatch("CreateUserModule/getFields", {
            roleID: role.value,
            step: step.value - 1,
            fields: "default"
          })
          .then(() => {
            key.value++;
          });
      } else {
        store
          .dispatch("CreateUserModule/getFields", {
            roleID: role.value,
            step: step.value - 1
          })
          .then(() => {
            key.value++;
          });
      }
    }
    function sendApiCall() {
      store.dispatch("CreateUserModule/store");
    }
    function createUser() {
      inputs.value?.validateInputs();
    }
    function handleCreate() {
      inputs.value?.saveDataToState();
      sendApiCall();
    }
    onUnmounted(() => {
      store.dispatch("CreateUserModule/resetState");
    })
    return {
      handleStepIncrement,
      handleBack,
      handleNextStep,
      handleChangePaymentAccount,
      createUser,
      loading,
      handleCreate,
      isAtLastStep,
      fields,
      key,
      inputs,
      step
    };
  }
});
