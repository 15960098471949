
/* eslint-disable @typescript-eslint/camelcase */
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch
} from "vue";
import { Field, ErrorMessage, Form } from "vee-validate";
import { useStore } from "vuex";
import UserCreateDropdown from "@/components/dropdown/UserCreateDropdown.vue";
import UserCreateDatepicker from "@/components/users/steps/UserCreateDatePicker.vue";
import UserCreateToggle from "@/components/users/steps/UserCreateToggle.vue";
import * as Yup from "yup";

export default defineComponent({
  name: "user-create-fields",
  components: {
    Field,
    ErrorMessage,
    UserCreateDropdown,
    UserCreateDatepicker,
    UserCreateToggle,
    Form
  },
  props: {
    title: {
      type: Object as PropType<object>,
      required: true
    },
    lastStep: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    currentStep: {
      type: Number as PropType<number>,
      required: true
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    const data = ref({});
    const user = computed(() => store.getters["CreateUserModule/user"]);
    const fields = computed(() => store.getters["CreateUserModule/fields"]);
    const submitButton = ref<null | HTMLButtonElement>();
    const paymentAccount = ref<string>("");
    const validationSchema = [];

    onMounted(() => {
      const object = {};
      for (let x = 0; x < fields.value.fields.length; x++) {
        object[fields.value.fields[x].key] = "";
      }
      data.value = object;
      for (let x = 0; x < fields.value.fields.length; x++) {
        if (user.value[fields.value.fields[x].key] !== undefined) {
          data.value[fields.value.fields[x].key] =
            user.value[fields.value.fields[x].key];
        }
      }
    });
    function handleChangePaymentField() {
      emit("changePaymentAccount", paymentAccount.value);
    }
    const isAtPaymentSettings = computed((): boolean => {
      return (
        fields.value.steps[props.currentStep - 2].title == "Payment information"
      );
    });
    function handleBackButton() {
      for (let x = 0; x < fields.value.fields.length; x++) {
        data.value[fields.value.fields[x].key] =
          user.value[fields.value.fields[x].key];
      }
    }
    function populatePaymentField(data: string) {
      paymentAccount.value = data;
    }
    function handleChange(value, field) {
      data.value[field] = value;
    }
    function handleClear(field) {
      data.value[field] = "";
    }
    const validateInputs = () => {
      submitButton.value?.click();
    };
    function saveDataToState() {
      store.dispatch("CreateUserModule/saveData", {
        value: data.value,
        payment: {
          isAtPaymentSettings: isAtPaymentSettings.value,
          accountType: paymentAccount.value
        }
      });
    }
    function handleNextStep() {
      if (!props.lastStep) {
        emit("nextStep");
      } else {
        emit("createUser");
      }
    }
    return {
      fields,
      data,
      validateInputs,
      handleBackButton,
      saveDataToState,
      handleChangePaymentField,
      paymentAccount,
      populatePaymentField,
      isAtPaymentSettings,
      handleChange,
      handleNextStep,
      validationSchema,
      submitButton,
      handleClear
    };
  }
});
