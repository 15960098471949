
import { defineComponent, onMounted, onUpdated, PropType, ref, watch } from "vue";

type DropdownData = {
  multiple: boolean;
  required: boolean;
  name: string;
  data: object;
  type: string;
  isHidden: boolean | undefined;
  key: string;
  presetValue: string | number | undefined;
};

export default defineComponent({
  props: {
    data: {
      type: Object as PropType<DropdownData>,
      required: true
    },
    initialValue: {
      type: String || Number || Array as PropType<string | number | number[]>,
      required: false
    }
  },
  setup(props, { emit }) {
    const value = ref();
    watch(value, () => {
      emit("changed", { value: value.value, field: props.data.key });
    });
    onUpdated(() => {
      if (undefined === props.data.presetValue) {
        value.value = props.initialValue;
      }
    });
    onMounted(() => {
      if (undefined !== props.data.presetValue) {
        value.value = props.data.presetValue.toString();
        emit("changed", { value: value.value, field: props.data.key });
      }
    });
    return {
      value
    };
  }
});
