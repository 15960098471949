<template>
  <!--begin::Dashboard-->
  <div class="row gy-5 g-xl-8">
    <CreateUserWizard />
  </div>
  <!--end::Dashboard-->
</template>

<script lang="js">
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import CreateUserWizard from "@/components/users/CreateUserWizard.vue";

export default defineComponent({
  name: "create-user",
  components: {
    CreateUserWizard
  },
});
</script>
