
import { defineComponent, onUpdated, ref, PropType, watch } from "vue";

type ToggleData = {
  type: string;
  required: false;
  name: string;
  key: string;
};

export default defineComponent({
  name: "user-create-toggle",
  props: {
    data: {
      type: Object as PropType<ToggleData>,
      required: true
    },
    initialValue: {
      type: Boolean as PropType<boolean>,
      default: false,
      required: false
    }
  },
  setup(props, { emit }) {
    const vmodel = ref();
    watch(vmodel, () => {
      emit("changed", { value: vmodel.value, field: props.data.key });
    });
    return {
      vmodel
    };
  }
});
