<template>
  <div
      class="notice d-flex rounded border border-dashed p-6 mb-10"
      :class="['bg-light-' + type, 'border-' + type]"
  >
    <!--begin::Icon-->
    <span class="svg-icon svg-icon-2tx me-4" :class="'svg-icon-' + type">
                        <inline-svg
                            src="media/icons/duotone/Code/Warning-1-circle.svg"
                        />
                      </span>
    <!--end::Icon-->
    <!--begin::Wrapper-->
    <div class="d-flex flex-stack flex-grow-1">
      <!--begin::Content-->
      <slot></slot>
      <!--end::Content-->
    </div>
    <!--end::Wrapper-->
  </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Notice",
  props: {
    type: {
      type: String,
      default: 'warning'
    }
  },
  data() {
    return {
      selectedValue: null
    }
  },
  watch: {
    selectedValue(value) {
      this.$emit('setRadio', value)
    }
  },
  methods: {
  }
});
</script>