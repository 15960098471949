
import { defineComponent, onUpdated, PropType, ref, watch } from "vue";

export default defineComponent({
  name: "user-create-datepicker",
  props: {
    data: {
      type: Object as PropType<any>,
      required: true
    },
    initialValue: {
      type: String as PropType<string>,
      required: false
    }
  },
  setup(props, { emit }) {
    const vmodel = ref();
    function handleClear() {
      emit("clear", props.data.value)
    }
    watch(vmodel, () => {
      emit("changed", { value: vmodel.value, field: props.data.key });
    });
    onUpdated(() => {
      vmodel.value = props.initialValue;
    })
    return {
      handleClear,
      vmodel
    };
  }
});
