import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!

  return (_openBlock(), _createBlock(_component_el_checkbox, {
    modelValue: _ctx.vmodel,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vmodel) = $event)),
    "active-color": "#13ce66",
    "inactive-color": "#ff4949"
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.data.label), 1)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}