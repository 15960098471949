<template>
  <!--begin::Wrapper-->
  <div className="w-100">
    <!--begin::Heading-->
    <div className="pb-10 pb-lg-15">
      <!--begin::Title-->
      <h2 className="fw-bolder d-flex align-items-center text-dark">
        Choose Role
      </h2>
      <!--end::Title-->

      <!--begin::Notice-->
      <div className="text-gray-400 fw-bold fs-6">
        All users need to be assigned to a role
      </div>
      <!--end::Notice-->
    </div>
    <!--end::Heading-->

    <!--begin::Input group-->
    <div className="fv-row">
      <!--begin::Row-->
      <div className="row">
        <!--begin::Col-->
        <div className="col-lg-12">
          <div v-for="(role, index) in possibleRoles" :key="role.id">
            <div class="form-check form-check-custom form-check-solid">
              <Field
                type="checkbox"
                class="form-check-input me-3"
                name="userRole"
                :value="role.id"
                :id="'create_user_role_' + role.id"
                @click="handleRoleChange(role.id)"
              />
              <label
                class="form-check-label"
                :for="'create_user_role_' + role.id"
              >
                <div class="fw-bolder text-gray-800">{{ role.title }}</div>
                <div class="text-gray-600" v-if="role.description">
                  {{ role.description }}
                </div>
              </label>
            </div>
            <div
              class="separator separator-dashed my-5"
              v-if="index < possibleRoles.length - 1"
            ></div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12 mt-16">
          <!--end::Col-->
          <Notice type="danger">
            <div class="fw-bold">
              <h4 class="text-gray-800 fw-bolder">
                Please select a role!
              </h4>
              <div class="fs-6 text-gray-600">
                Users should always have a role assigned.
              </div>
            </div>
          </Notice>
        </div>
      </div>
      <!--end::Row-->
    </div>
    <!--end::Input group-->
  </div>
  <!--end::Wrapper-->
</template>

<script>
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import { mapActions, mapGetters } from "vuex";
import Notice from "@/components/general/Notice";

export default defineComponent({
  name: "step-1",
  computed: {
    ...mapGetters("CreateUserModule", ["roleId", "possibleRoles"])
  },
  methods: {
    ...mapActions("CreateUserModule", {
      setRoleId: "setRoleId",
      fetchRoles: "fetchPossibleRoles"
    }),
    handleRoleChange(roleId) {
      this.setRoleId(roleId);
    }
  },
  mounted() {
    this.fetchRoles();
  },
  components: {
    Field,
    Notice
  }
});
</script>
